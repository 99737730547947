
import { computed, defineComponent, reactive, ref } from "vue";
import BaseCard from "@/app/core/components/cards/BaseCard.vue";
import BaseInput from "@/app/core/components/forms/BaseInput.vue";
import { useProfileModule } from "@/store/modules/ProfileModule";
import { container } from "tsyringe";
import { UserService } from "@/app/core/services/UserService";
import { UserResponseContract } from "@/app/core/interfaces/services/user/UserResponseContract";
import { UserUpdateRequestContract } from "@/app/core/interfaces/services/user/UserUpdateRequestContract";
import { helpers, required } from "@vuelidate/validators";
import { DocumentUploadRequestContract } from "@/app/core/interfaces/services/document/DocumentUploadRequestContract";
import useVuelidate from "@vuelidate/core";
import { DocumentService } from "@/app/core/services/DocumentService";
import { useToast } from "vue-toastification";
import { determineFileType } from "@/app/core/utils/determineFileType";
import BaseFileUpload from "@/app/core/components/forms/BaseFileUpload.vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";
import { downloadFile } from "@/app/core/utils/restHelpers";
import BaseButton from "@/app/core/components/buttons/BaseButton.vue";

export default defineComponent({
  name: "MyDocuments",
  components: { BaseButton, BaseFileUpload, BaseInput, BaseCard, BaseIcon },
  async setup() {
    const profileCtx = useProfileModule();
    const toast = useToast();
    const userService: UserService = container.resolve(UserService);
    const documentService: DocumentService = container.resolve(DocumentService);
    const updatingW9 = ref<boolean>(false);
    const updatingCoi = ref<boolean>(false);

    if (!profileCtx.state.user) {
      await profileCtx.actions.fetchUser();
    }
    if (!profileCtx.state.documents) {
      await profileCtx.actions.fetchDocuments();
    }

    const documentsForm = reactive<DocumentUploadRequestContract>({
      w9: undefined,
      coi: undefined,
    });

    const rules = {
      w9: {
        required: helpers.withMessage("File is required", required),
      },
      coi: {
        required: helpers.withMessage("File is required", required),
      },
    };

    const v$ = useVuelidate(rules, documentsForm as any);

    const updateIccMcForm = reactive<UserUpdateRequestContract>({
      iccMc: profileCtx.getters.getUser?.data.iccMc,
    });

    const submit = () => {
      userService
        .update<UserUpdateRequestContract, UserResponseContract>(updateIccMcForm)
        .then((res) => {
          toast.success("IccMc number was updated!");
          profileCtx.mutations.setUser(res);
        });
    };

    const updateW9 = () => {
      v$.value.w9.$touch();
      if (!v$.value.w9.$invalid) {
        const formData = new FormData();
        formData.append("w9", documentsForm.w9!);
        toast.info("Uploading w9 file");
        documentService.update(formData).then(() => {
          toast.success("Successfully uploaded w9 file");
          profileCtx.actions.fetchDocuments();
          v$.value.w9.$reset();
          updatingW9.value = false;
          documentsForm.w9 = undefined;
        });
      }
    };

    const updateCoi = () => {
      v$.value.coi.$touch();
      if (!v$.value.coi.$invalid) {
        const formData = new FormData();
        formData.append("coi", documentsForm.coi!);
        toast.info("Uploading COI file");
        documentService.update(formData).then(() => {
          toast.success("Successfully uploaded COI file");
          profileCtx.actions.fetchDocuments();
          v$.value.coi.$reset();
          updatingCoi.value = false;
          documentsForm.coi = undefined;
        });
      }
    };

    const deleteDocument = (id: number) => {
      if (window.confirm("Do you really want to delete document?")) {
        toast.info("Deleting document");
        documentService.delete(id).then(() => {
          toast.success("Successfully deleted document");
          profileCtx.actions.fetchDocuments();
        });
      }
    };

    const downloadDocument = (id: number) => {
      toast.info("Downloading file");
      documentService.downloadDocument(id).then((res) => {
        downloadFile(res.file, res.originalName);
      });
    };

    return {
      updateIccMcForm,
      submit,
      updateCoi,
      updateW9,
      determineFileType,
      deleteDocument,
      downloadDocument,
      documentsForm,
      currentW9: computed(() => profileCtx.getters.getW9),
      currentCoi: computed(() => profileCtx.getters.getCoi),
      updatingW9,
      updatingCoi,
      v$,
    };
  },
});
